function Team() {
  return (
    <div id="team" className="section-team">
      <div className="container mx-auto py-10">
        <h2 className="text-complementary text-5xl text-center">Team</h2>
        <div className="members flex flex-wrap flex-col sm:flex-row content-center sm:justify-center space-x-0 sm:space-x-10 text-center text-xl text-complementary mb-7">
          <figure className="member">
            <img srcSet="/img/pfp-melissy.png 1x, /img/pfp-melissy@2x.png 2x" alt="PFP for @melissy" />
            <figcaption className="mt-3">
              <p className="mb-0">@melissy</p>
              <p className="mb-0">Artist</p>
            </figcaption>
          </figure>
          <figure className="member mb-8 sm:mb-0">
            <img srcSet="/img/pfp-mcspicy.png 1x, /img/pfp-mcspicy@2x.png 2x" alt="PFP for @mcspicy" />
            <figcaption className="mt-3">
              <p className="mb-0">@mcspicy</p>
              <p className="mb-0">Dev</p>
            </figcaption>
          </figure>
          <figure className="member mb-8 sm:mb-0">
            <img srcSet="/img/pfp-moonhunter.png 1x, /img/pfp-moonhunter@2x.png 2x" alt="PFP for @moonhunter" />
            <figcaption className="mt-3">
              <p className="mb-0">@moonhunter</p>
              <p className="mb-0">Collaborations Partner</p>
            </figcaption>
          </figure>
        </div>
        <div className="team-btn text-center">
          <a href="https://docs.cattokatsu.com/info/team" target="_blank" rel="noreferrer" className="uppercase text-white text-3xl bg-primary px-10 py-5 rounded-lg inline-block">Team Details</a>
        </div>
      </div>
    </div>
  );
}

export default Team;
