const faqs = [
  {
    qns: "What is Catto Katsu (CTK)?",
    ans: "Catto Katsu a character-based, generative NFT project on the Ethereum blockchain. We aim for CTK to be a cute, approachable, and fun NFT collection for both newcomers and seasoned NFT collectors.",
  },
  {
    qns: "What can I do with my Catto",
    ans: "You are free to do anything with your Cattos, for both personal and commercial purposes, under a non-exclusive license.",
  },
  {
    qns: "How is Catto Katsu different from other NFT projects?",
    ans: "Catto Katsu is probably one of the first NFTs to have a unique floor price safeguard via the adoption marketplace, starting from 0.01 Ξ, and gradually increasing depending on secondary market sales, up to the original mint price of 0.05 Ξ. Please read our white paper to learn more.",
  },
  {
    qns: "Is it safe to mint Catto Katsu NFT, can I trust the the team?",
    ans: "The reserved funds for the adoption marketplace is governed by the contract, don't trust us, trust the code within the contract. The contract will be made public before the minting date.",
  },
  {
    qns: "Who are the team members",
    ans: "The team is based in Singapore. @melissy is a former writer in a number of publications, in both the traditional and digital space. She has a great flair in digital arts, and it has been a great part of her life. Catto Katsu is one of her many brainchilds. @mcspicy is the developer, he currently works in one of the top technology companies within the SEA region.",
  },
  {
    qns: "The team is small, I'm concerned about the roadmap execution.",
    ans: "We totally understand. That is why we plan to release our white papers in phases (first one already up). Whatever that we have in the phase 1 white paper, we are confident that we can execute. We are actively looking for the right individuals to join our team.",
  },
  {
    qns: "Does the team plans to dox themselves?",
    ans: "Please see team details in the white paper.",
  },
  {
    qns: "Future plans for Catto Katsu?",
    ans: "We plan to have a staking mechanism, along with a utility token. These will be the prerequisites for more exciting developments such as 2nd-gen, and breeding. These details will be released in the white paper for phase 2. There is no immediate plan to have a metaverse integration or a game yet. We don't think we have the capacity to pull these off yet, especially for a game. It takes a capable team of game developers and artists to create a non-crappy one, one that people will actually play. So a game will be much further in the pipeline.",
  },
]

function FAQ() {
  return (
    <div id="faq" className="section-faq" style={{background: "#f2f2f2"}}>
      <div className="container mx-auto py-10">
        <h2 className="text-complementary text-5xl text-center">FAQ</h2>
        
        <div className="faqs max-w-screen-lg mx-auto">
          {
            faqs.map((qns, idx) => {
              return (
                <div className="item" key={'qns-' + idx}>
                  <h4 className="qns text-555 text-4xl">{qns.qns}</h4>
                  <p className="ans text-555 text-2xl">{qns.ans}</p>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default FAQ;
