import { useState, useEffect} from "react";
import {ethers} from "ethers";
import { toast } from 'react-toastify'
import CattoKatsuABI from '../../contracts/CattoKatsu.json'

function MyCattosPage() {
  const [isConnected, setIsConnected] = useState(false)
  const [provider, setProvider] = useState(null)
  const [walletAddress, setWalletAddress] = useState("")
  const [mintContract, setMintContract] = useState(null)
  const [userTokens, setUserTokens] = useState([])
  
  const getMintContract = (theSigner) => {
    return new ethers.Contract(process.env.REACT_APP_MINT_CONTRACT, CattoKatsuABI.abi, theSigner)
  }
  
  useEffect(() => {
    const checkWalletConnect = async () => {
      const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
      setProvider(currentProvider)
      
      const accounts = await currentProvider.listAccounts()
      if (accounts.length > 0) {
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setWalletAddress(addr)
        
        const contract = getMintContract(currentSigner)
  
        let tokens = []
  
        try {
          tokens = await contract.getOwnerTokenIDs(addr)
        } catch (err) {
          toast.error('Failed to get token count, are you on the right network?')
          return
        }
        
        setMintContract(contract)
        setUserTokens(tokens)
      }
    }
    
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', async (accounts) => {
        if (accounts.length === 0) {
          setIsConnected(false)
          setWalletAddress("")
          return
        }
        
        const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setWalletAddress(addr)
        
        const contract = getMintContract(currentSigner)
        const tokens = await contract.getOwnerTokenIDs(addr)
        setUserTokens(tokens)
  
        setMintContract(contract)
      });
    }
  
    checkWalletConnect()
  }, [])
  
  const connectWallet = async () => {
    if (!isConnected) {
      const accounts = await provider.send("eth_requestAccounts", [])
      if (accounts && accounts.length > 0) {
        const currentSigner = await provider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setWalletAddress(addr)
      }
    }
  }
  
  if (!window.ethereum) {
    return (
      <div className="mint-page">
        <div className="container mx-auto my-8">
          <section className="mint-info text-center text-xl text-complementary">
            <p>No Metamask detected</p>
          </section>
        </div>
      </div>
    )
  }
  
  return (
    <div className="my-cattos-page min-h-screen">
      <div className="container mx-auto my-8">
        <h1 className="text-primary text-5xl text-center">My Cattos</h1>
  
        <section className="connect-wallet text-center mb-8">
          <button className="bg-primary text-white rounded px-5 py-2 ml-5" onClick={connectWallet}>{isConnected ? walletAddress : "Connect Wallet"}</button>
        </section>
  
        <section className="catto-list text-center mx-auto mb-8 grid grid-cols-3 gap-5 max-w-screen-lg ">
          { userTokens.length > 0 ?
            userTokens.map(token => {
              return (
                <figure className='text-complementary' key={token.toString()}>
                  {
                    process.env.REACT_APP_ENV !== 'production' ? (
                      <img src={process.env.REACT_APP_IMAGE_URL + '0.png'}  alt={'Token ID ' + token.toString()} className="max-w-full" />
                    ) : (
                      <img src={process.env.REACT_APP_IMAGE_URL + token.toString() + '.png'}  alt={'Token ID ' + token.toString()} className="max-w-full" />
                    )
                  }
                  <figcaption className='text-left mt-3'>{'Catto Katsu #' + token.toString()}</figcaption>
                </figure>
              )
            }) : ''
          }
        </section>
      </div>
    </div>
  );
}

export default MyCattosPage;
