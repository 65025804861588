import "./SiteHeader.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

function SiteHeader() {
  return (
    <div className="site-header bg-light-base">
      <div className="container flex flex-col lg:flex-row flex-wrap justify-between mx-auto py-6">
        
        <div className="branding flex items-center mx-auto lg:mx-0">
          <figure className="logo mr-5">
            <HashLink smooth to={'/#'}><img srcSet="/img/logo-ctk.png 1x, /img/logo-ctk@2x.png 2x" alt="Catto Katsu NFT Branding Logo" /></HashLink>
          </figure>
          <div className="text text-333 font-title"><HashLink smooth to={'/#'}>CATTO KATSU</HashLink></div>
        </div>
        
        <div className="navs flex flex-col justify-center text-center lg:text-right text-555 mx-auto lg:mx-0 mt-5 lg:mt-0">
          <div className="socials mb-5">
            <ul className="list-none">
              <li><a href="https://discord.gg/tQvBanhQqu" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'discord']} /></a></li>
              <li><a href="https://twitter.com/CattoKatsuNFT" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a></li>
              {/*<li><a href="https://opensea.io" target="_blank" rel="noreferrer"><img src="/img/icon-opensea.svg" style={{width: '1.3rem'}} alt="Catto Katsu Opensea Link" /></a></li>*/}
            </ul>
          </div>
          <div className="menu uppercase">
            <ul className="list-none">
              <li><HashLink smooth to={'/#'}>Home</HashLink></li>
              <li><HashLink smooth to={'/#about'}>About</HashLink></li>
              <li><HashLink smooth to={'/#roadmap'}>Roadmap</HashLink></li>
              <li><HashLink smooth to={'/#team'}>Team</HashLink></li>
              <li><HashLink smooth to={'/#faq'}>FAQ</HashLink></li>
              <li className="mt-5 sm:mt-0"><Link to="/my-cattos" className="text-white bg-primary px-5 py-2 rounded">My Cattos</Link></li>
              <li className="mt-5 sm:mt-0"><Link to="/ftm/claim" className="text-white bg-primary px-5 py-2 rounded">Claim</Link></li>
              <li className="mt-5 sm:mt-0"><Link to="/ftm/mint" className="text-white bg-complementary px-5 py-2 rounded">Mint</Link></li>
            </ul>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default SiteHeader;
