import { useState, useEffect} from "react";
import {BigNumber, ethers} from "ethers";
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CattoKatsuABI from '../../contracts/CattoKatsu.json'

function MintPage() {
  const [disableMintBtn, setDisableMintBtn] = useState(true)
  const [minting, setMinting] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [provider, setProvider] = useState(null)
  const [chainID, setChainID] = useState(null)
  const [walletAddress, setWalletAddress] = useState("")
  const [mintContract, setMintContract] = useState(null)
  const [userTokens, setUserTokens] = useState([])
  const [globalMintCount, setGlobalMintCount] = useState(0)
  
  const getMintContract = (theSigner) => {
    return new ethers.Contract(process.env.REACT_APP_MINT_CONTRACT, CattoKatsuABI.abi, theSigner)
  }
  
  useEffect(() => {
    const checkWalletConnect = async () => {
      const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
      setProvider(currentProvider)
      
      const accounts = await currentProvider.listAccounts()
      if (accounts.length > 0) {
        const { chainId } = await currentProvider.getNetwork()
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
  
        const contract = getMintContract(currentSigner)
        
        let tokens = []
  
        try {
          tokens = await contract.getOwnerTokenIDs(addr)
        } catch (err) {
          toast.error('Failed to get token count, are you on the right network?')
          return
        }
        
        let globalTokenCount = 0
        try {
          globalTokenCount = await contract.totalSupply()
          globalTokenCount = globalTokenCount.toString()
        } catch (err) {
          toast.error('Failed to get current token supply, are you on the right network?')
          return
        }
        
        setMintContract(contract)
        setUserTokens(tokens)
        setGlobalMintCount(globalTokenCount)
      }
    }
    
    if (window.ethereum) {
      checkWalletConnect().then(() => {
        setDisableMintBtn(false)
      })
      
      window.ethereum.on('accountsChanged', async (accounts) => {
        if (accounts.length === 0) {
          setIsConnected(false)
          setWalletAddress("")
          return
        }
  
        const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
        const { chainId } = await currentProvider.getNetwork()
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()

        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
        
        const contract = getMintContract(currentSigner)
        const tokens = await contract.getOwnerTokenIDs(addr)
        const globalTokenCount = await contract.totalSupply()
        setMintContract(contract)
        setUserTokens(tokens)
        setGlobalMintCount(globalTokenCount.toString())
      });
      
      window.ethereum.on('chainChanged', async (chainID) => {
        const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
        const { chainId } = await currentProvider.getNetwork()
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
  
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
        setMintContract(getMintContract(currentSigner))
      });
    }
  }, [])
  
  const connectWallet = async () => {
    if (!isConnected) {
      const accounts = await provider.send("eth_requestAccounts", [])
      if (accounts && accounts.length > 0) {
        const { chainId } = await provider.getNetwork()
        const currentSigner = await provider.getSigner()
        const addr = await currentSigner.getAddress()
  
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
      }
    }
  }
  
  const handleMintAction = async e => {
    e.preventDefault()
    setDisableMintBtn(true)
    setMinting(true)
    let toMintNum = document.getElementById('amtToMint').value
    if (isNaN(toMintNum) || toMintNum <= 0 || toMintNum > 5) {
      toast.error('Must be between 1 - 5')
      setDisableMintBtn(false)
      setMinting(false)
      return
    }
    
    const mintPrice = ethers.utils.parseEther(process.env.REACT_APP_MINT_PRICE)
    const totalCost = mintPrice.mul(BigNumber.from(toMintNum))
    const mintTxn = await mintContract.mintCatto(toMintNum, {value: totalCost})
    
    provider.once(mintTxn.hash, async (transaction) => {
      setDisableMintBtn(false)
      setMinting(false)
      toast.success('🎉 Minted successfully!')
      const userCurrentTokens = await mintContract.getOwnerTokenIDs(walletAddress)
      setUserTokens(userCurrentTokens)
      const globalCount = parseInt(globalMintCount, 10) + 1
      setGlobalMintCount(globalCount)
    });
  
  }
  
  if (!window.ethereum) {
    return (
      <div className="mint-page">
        <div className="container mx-auto my-8">
          <section className="mint-info text-center text-xl text-complementary">
            <p>You will need a Metamask wallet in order to mint a Catto NFT. Please see <a href="https://metamask.io/download.html" target="_blank" rel="noreferrer" className="underline">Metamask website</a> for download and installation instruction</p>
          </section>
        </div>
      </div>
    )
  }
  
  if (isConnected && chainID !== process.env.REACT_APP_CHAIN_ID) {
    return (
      <div className="mint-page">
        <div className="container mx-auto my-8">
          <section className="mint-info text-center text-xl text-complementary">
            <p>Please switch to Ethereum</p>
          </section>
        </div>
      </div>
    )
  }
  
  return (
    <div className="mint-page min-h-screen">
      <div className="container mx-auto my-8">
        <section className="mint-info text-center text-xl text-complementary">
          <h1 className="text-complementary text-5xl">Mint Your Cattos!</h1>
          <p><span className="text-888">Mint Date & Time:</span> 1 Dec 2021, 6 AM UTC / 2 PM SGT /  1 AM EST</p>
          <p><span className="text-888">Max Mint / Transaction:</span> {process.env.REACT_APP_MINT_MAX_TXN}</p>
          <p><span className="text-888">Max Mint / Wallet:</span> {process.env.REACT_APP_MINT_MAX_WALLET}</p>
        </section>
        
        <hr className="mb-8"/>
        
        <section className="connect-wallet text-center mb-8">
          <button className="bg-primary text-white rounded px-5 py-2 ml-5" onClick={connectWallet}>{isConnected ? walletAddress : "Connect Wallet"}</button>
        </section>
  
        <div className="user-minted-count text-center text-888">
          <p className="text-xl">Global Mint Count: <span className="text-complementary">{globalMintCount} / {process.env.REACT_APP_MINT_SUPPLY}</span></p>
          <p className="text-xl">You have minted: <span className="text-complementary">{userTokens.length} / {process.env.REACT_APP_MINT_MAX_WALLET}</span></p>
        </div>
        
        {
          isConnected && userTokens.length < process.env.REACT_APP_MINT_MAX_WALLET ? (
            <section className="mint-zone">
              <form className="text-center" onSubmit={handleMintAction}>
                <input type="text" defaultValue={1} id="amtToMint" className="mt-0 px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black" />
                <button type="submit" className="bg-complementary text-white rounded px-5 py-2 ml-5" disabled={disableMintBtn}>
                  {minting ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}
                  Mint
                </button>
              </form>
            </section>
          ) : ''
        }
        
      </div>
    </div>
  );
}

export default MintPage;
