function HeroCover() {
  return (
    // <div id="home" className="section-home" style={{
    //   background: `url(/img/bg-cover-ctk.jpg) no-repeat`,
    //   backgroundSize: 'cover',
    //   height: '100vh',
    // }} />
  
    <div id="home" className="section-home">
      <img srcSet="/img/bg-cover-ctk-v2.png 1x, /img/bg-cover-ctk-v2@2x.png 2x" alt="Catto Katsu Hero Cover" className="w-full" />
    </div>
  );
}

export default HeroCover;
