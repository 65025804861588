function Roadmap() {
  const launchRoadmap = [
    {
      progress: '25%',
      items: [
        "Fund 10 Ξ to the community wallet.",
        "5 randomly selected winners will receive 0.1 Ξ each."
      ],
    },
    {
      progress: '50%',
      items: [
        "Fund 10 Ξ to the community wallet.",
        "8 randomly selected winners will receive 0.1 Ξ each.",
      ],
    },
    {
      progress: '75%',
      items: [
        "Fund 10 Ξ to the community wallet.",
        "10 randomly selected winners will receive 0.1 Ξ each.",
        "Kickoff the process to expand the team",
      ],
    },
    {
      progress: '100%',
      items: [
        "Fund 10 Ξ to the community wallet.",
        "15 randomly selected winners will receive 0.1 Ξ each.",
        "Set aside 10 ETH for community project grants.",
      ],
    },
    {
      progress: 'Post-mint',
      items: [
        "Artion & Paintswap secondary market listing.",
        "Rarity listings on Rarity Sniper.",
      ],
    },
  ]
  
  const devRoadmap = [
    {
      progress: '2021 Q4',
      items: [
        "Catto Shelter (adoption marketplace) to go live on Fantom",
        "Ethereum mint opens",
      ],
    },
    {
      progress: '2022 Q1',
      items: [
        "Catto Shelter to go live on Ethereum",
        "Harmony One Launch",
        "Catto Shelter to go live on Harmony One",
        "DAO formation",
        "Phase 2 white paper & roadmap release",
        "Gen. 2 development",
      ],
    },
    {
      progress: '2022 Q2',
      items: [
        "Utility token & staking development",
        "2nd-gen NFT drop, mint-able only by 1st-gen holders *",
      ],
    },
    {
      progress: '2022 Q3',
      items: [
        "Utility token & staking development",
      ],
    },
    {
      progress: '2022 Q4',
      items: [
        "Utility token & staking live",
      ],
    },
  ]
  
  return (
    <div id="roadmap" className="section-roadmap text-white">
  
      <div className="launch-roadmap bg-primary">
        <div className="container mx-auto py-10">
          <h2 className="text-center text-5xl">
            <img srcSet="/img/icon-launch-roadmap.png 1x, /img/icon-launch-roadmap@2x.png 2x" alt="Launch Roadmap Heading Icon" className="inline" />
            <span className="ml-5">Fantom Launch Roadmap</span>
          </h2>
          <div className="milestones">
            {
              launchRoadmap.map((milestone, idx) => {
                let milestoneMarginClass = " mb-10"
                if (idx === launchRoadmap.length -1) {
                  milestoneMarginClass = ""
                }
        
                return (
                  <div className={"grid grid-cols-5 gap-4" + milestoneMarginClass} key={'lrms-' + idx}>
                    <div className="font-heading text-4xl text-center">{milestone.progress}</div>
                    <div className="col-span-4 text-3xl">
                      <ul>
                        {
                          milestone.items.map((item, idx) => {
                            let itemMarginClass = " mb-5"
                            if (idx === milestone.items.length -1) {
                              itemMarginClass = ""
                            }
                            return (
                              <li className={itemMarginClass} key={'lrms-item-' + idx}>{item}</li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
  
      <div className="dev-roadmap bg-complementary">
        <div className="container mx-auto py-10">
          <h2 className="text-center text-5xl">
            <img srcSet="/img/icon-dev-roadmap.png 1x, /img/icon-dev-roadmap@2x.png 2x" alt="Development Roadmap Heading Icon" className="inline" />
            <span className="ml-5">Development Roadmap</span>
          </h2>
          <div className="milestones mb-10">
            {
              devRoadmap.map((milestone, idx) => {
                let milestoneMarginClass = " mb-10"
                if (idx === devRoadmap.length -1) {
                  milestoneMarginClass = ""
                }
            
                return (
                  <div className={"grid grid-cols-5 gap-4" + milestoneMarginClass} key={'drms-' + idx}>
                    <div className="font-heading text-4xl text-center">{milestone.progress}</div>
                    <div className="col-span-4 text-3xl">
                      <ul>
                        {
                          milestone.items.map((item, idx) => {
                            let itemMarginClass = " mb-5"
                            if (idx === milestone.items.length -1) {
                              itemMarginClass = ""
                            }
                            return (
                              <li className={itemMarginClass} key={'drms-item-' + idx}>{item}</li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="notes text-base mb-10">
            <div className={"grid grid-cols-5 gap-4"}>
              <div className="" />
              <div className="col-span-4">
                <p className="mb-0">* Details to be release in Phase 2 white paper</p>
              </div>
            </div>
          </div>
          <div className="view-litepaper text-center">
            <a href="https://docs.cattokatsu.com" target="_blank" rel="noreferrer" className="uppercase text-complementary text-3xl bg-light-base px-10 py-5 rounded-lg inline-block">White Paper</a>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Roadmap;
