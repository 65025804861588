import './SiteFooter.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SiteFooter() {
  return (
    <div className="site-footer bg-light-base text-center text-complementary text-lg">
      <div className="container mx-auto py-6">
        <span className="copyright-text">&copy; 2021 Catto Katsu</span>
        <span className="mx-5">|</span>
        <ul className="list-none inline-block">
          <li><a href="https://discord.gg/tQvBanhQqu" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'discord']} /></a></li>
          <li><a href="https://twitter.com/CattoKatsuNFT" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={['fab', 'twitter']} /></a></li>
          {/*<li><a href="https://opensea.io" target="_blank" rel="noreferrer"><img src="/img/icon-opensea-footer.svg" style={{width: '1.1rem'}} alt="Catto Katsu Opensea Link" /></a></li>*/}
        </ul>
      </div>
    </div>
  );
}

export default SiteFooter;
