import { useState, useEffect} from "react";
import {ethers} from "ethers";
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CattoKatsuABI from '../../contracts/CattoKatsu.json'

function ClaimPage() {
  const [disableMintBtn, setDisableMintBtn] = useState(true)
  const [minting, setMinting] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [provider, setProvider] = useState(null)
  const [chainID, setChainID] = useState(null)
  const [walletAddress, setWalletAddress] = useState("")
  const [mintContract, setMintContract] = useState(null)
  const [isInList, setIsInList] = useState(false)
  const [hasClaimed, setHasClaimed] = useState(false)
  
  const getMintContract = (theSigner) => {
    return new ethers.Contract(process.env.REACT_APP_MINT_CONTRACT, CattoKatsuABI.abi, theSigner)
  }
  
  useEffect(() => {
    const checkWalletConnect = async () => {
      const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
      setProvider(currentProvider)
      
      const accounts = await currentProvider.listAccounts()
      if (accounts.length > 0) {
        const { chainId } = await currentProvider.getNetwork()
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
        
        const contract = getMintContract(currentSigner)
  
        setMintContract(contract)
  
        let claimStatus = []
        
        try {
          claimStatus = await contract.checkOgClaimStatus(addr)
        } catch (err) {
          toast.error('Failed to check claim status, are you on the right network?')
          return
        }

        setIsInList(claimStatus[0])
        setHasClaimed(claimStatus[1])
      }
    }
    
    if (window.ethereum) {
      checkWalletConnect().then(() => {
        setDisableMintBtn(false)
      })
      
      window.ethereum.on('accountsChanged', async (accounts) => {
        if (accounts.length === 0) {
          setIsConnected(false)
          setWalletAddress("")
          return
        }
        
        const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
        const { chainId } = await currentProvider.getNetwork()
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
        
        const contract = getMintContract(currentSigner)
        setMintContract(contract)
  
        let claimStatus = await contract.checkOgClaimStatus(addr)
        setIsInList(claimStatus[0])
        setHasClaimed(claimStatus[1])
      });
      
      window.ethereum.on('chainChanged', async (chainID) => {
        const currentProvider = new ethers.providers.Web3Provider(window.ethereum)
        const { chainId } = await currentProvider.getNetwork()
        const currentSigner = await currentProvider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
        setMintContract(getMintContract(currentSigner))
      });
    }
  }, [])
  
  const connectWallet = async () => {
    if (!isConnected) {
      const accounts = await provider.send("eth_requestAccounts", [])
      if (accounts && accounts.length > 0) {
        const { chainId } = await provider.getNetwork()
        const currentSigner = await provider.getSigner()
        const addr = await currentSigner.getAddress()
        
        setIsConnected(true)
        setChainID(chainId.toString())
        setWalletAddress(addr)
      }
    }
  }
  
  const handleClaimAction = async e => {
    e.preventDefault()
    setDisableMintBtn(true)
    setMinting(true)
    
    const mintTxn = await mintContract.claimCatto()
    
    provider.once(mintTxn.hash, async (transaction) => {
      setDisableMintBtn(false)
      setMinting(false)
      setHasClaimed(true)
      toast.success('🎉 Minted successfully!')
    })
  }
  
  if (!window.ethereum) {
    return (
      <div className="mint-page">
        <div className="container mx-auto my-8">
          <section className="mint-info text-center text-xl text-complementary">
            <p>You will need a Metamask wallet in order to claim a Catto NFT. Please see <a href="https://metamask.io/download.html" target="_blank" rel="noreferrer" className="underline">Metamask website</a> for download and installation instruction</p>
          </section>
        </div>
      </div>
    )
  }
  
  if (isConnected && chainID !== process.env.REACT_APP_CHAIN_ID) {
    return (
      <div className="mint-page">
        <div className="container mx-auto my-8">
          <section className="mint-info text-center text-xl text-complementary">
            <p>Please switch to Ethereum</p>
          </section>
        </div>
      </div>
    )
  }
  
  return (
    <div className="mint-page min-h-screen">
      <div className="container mx-auto my-8">
        <section className="mint-info text-center text-xl text-complementary">
          <h1 className="text-primary text-5xl">Claim a Catto</h1>
          <p className="text-888">Claiming of a free Catto is only available for <span className="bg-complementary text-white">Ancestral Catto</span> role, and people who have won in various giveaways and contests.</p>
          {/*<p><span className="text-888">Starts:</span> 22 Nov 2021, 06:00 UTC / 14:00 SGT / 01:00 EST</p>*/}
        </section>
        
        <hr className="mb-8"/>
        
        <section className="connect-wallet text-center mb-8">
          <button className="bg-primary text-white rounded px-5 py-2 ml-5" onClick={connectWallet}>{isConnected ? walletAddress : "Connect Wallet"}</button>
        </section>
  
        {
          isInList ? (
  
            hasClaimed ? (<p className="text-center">You have already claimed a Catto</p>) : (
              <section className="mint-zone">
                <form className="text-center" onSubmit={handleClaimAction}>
                  <button type="submit" className="bg-complementary text-white rounded px-5 py-2 ml-5" disabled={disableMintBtn}>
                    {minting ? (<FontAwesomeIcon icon="circle-notch" spin className="mr-2" />) : ''}
                    Claim
                  </button>
                </form>
              </section>
            )
            
          ) : (<p className="text-center">You are not on the list to claim a Catto</p>)
        }
      
      </div>
    </div>
  );
}

export default ClaimPage;
