import './App.css';
import { library as faLib } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteHeader from "./components/SiteHeader";
import SiteFooter from "./components/SiteFooter";
import SectionHeroCover from "./components/sections/HeroCover";
import SectionAbout from "./components/sections/About";
import SectionRoadmap from "./components/sections/Roadmap";
import SectionTeam from "./components/sections/Team";
import SectionFAQ from "./components/sections/FAQ";
import {Route, Switch} from "react-router-dom";
import MintPage from "./components/mint/MintPage";
import ClaimPage from "./components/mint/ClaimPage";
import MyCattosPage from "./components/mycattos/MyCattosPage";

faLib.add(fab, fas)

function App() {
  return (
    <div className="App">
      <SiteHeader />
  
      <Switch>
        <Route exact path="/my-cattos">
          <MyCattosPage />
        </Route>
        <Route exact path="/ftm/mint">
          <MintPage />
        </Route>
        <Route exact path="/ftm/claim">
          <ClaimPage />
        </Route>
        <Route exact path="/">
          <div className="home-sections">
            <SectionHeroCover />
            <SectionAbout />
            <SectionRoadmap />
            <SectionTeam />
            <SectionFAQ />
          </div>
        </Route>
      </Switch>
  
      <SiteFooter />
      <ToastContainer position="bottom-center" autoClose={5000}/>
    </div>
  );
}

export default App;
